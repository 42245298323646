import React from 'react';
// import Container from 'react-bootstrap/Container';
// import WebHeading from './common/WebHeading';
// import Fade from 'react-reveal/Fade';
// import mercury_finance_animation from '../assets/images/HomePage/mercury_finance_animation.png';
// import timeboundgif from '../assets/images/HomePage/Presentation.mp4';
const ComparisonTBS = () => {
    return (
        <>
        <section >
           
        <video loop muted autoPlay playsInline width="100%" style={{marginTop:"0px"}}> 
                                            <source src={require('../assets/images/HomePage/Presentation.mp4')} type="video/mp4" />
                                        </video>
         
        
                       
        </section>
           
            
                                        </>           
    )
}

export default ComparisonTBS;