import React from 'react';
import { WebOffBotton } from '../../component/common/WebOffBotton';
import { useEffect,useState } from 'react';
import ButtonLoad from 'react-bootstrap-button-loader';
import { StakingABI, StakingBLACKAddress } from '../../abi/abi';
import { ethers } from 'ethers';
import { ToastContainer, Toast, Zoom, Bounce, toast} from 'react-toastify';
import CountdownWrapper  from '../../DashboardPage/LaunchpadCards/snippets/CountdownWrapper';

const StockFilter = ({Item,handleOpen,handleOpens}) => {
    const [lct,setlct] = useState("");
    const[day,setTime4]= useState("");
    const[hour,setTim1]= useState("");
    const[min,setTim2]= useState("");
    const[sec,setTim3]= useState("");
    const[lock,setlock]= useState(false);

    const[loader, setLoader] = useState(false);

    const connectToEthereum = async () => {
        try {
          if (window.ethereum) {
            let k = await window.ethereum.request({ method: 'eth_requestAccounts' });
            console.log("K",k)
            const web3= new ethers.providers.Web3Provider(window.ethereum);
            return web3;
          } else {
            throw new Error('No Ethereum wallet found.');
          }
        } catch (error) {
          console.error(error);
          return null;
        }
      };
    const claim = async() =>{
      setLoader(true);
      try{
        const web31 = await connectToEthereum();
        if (!web31) return;
    
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const account = accounts[0]; // Use the first account
    
        console.log("Connected Successfully", account);
    
        // Create contract instance with the correct order of arguments
        const dimeUSDCLPstakingContract = new ethers.Contract(StakingBLACKAddress, StakingABI, web31.getSigner(account));
    
        // const val = ethers.utils.formatUnits(100000000000000, 0);
        // let k = Web3.utils.toBN(1000000000000000000n);
        // const val11 = ethers.utils.formatUnits(100000000000000, 18);
        // const val1 =  ethers.utils.parseUnits(val11, 18);;
        // Send the transaction and wait for it to be mined
        const mintTx = await dimeUSDCLPstakingContract.claimReward();
        await mintTx.wait();
        console.log("minttx",mintTx.hash);
        // toast.success(` "Successfully Minted JUSD", ${(mintTx.hash)} `)
        let id = "https://testnet.snowtrace.io/tx/" + mintTx.hash;
        toast.success(toastDiv(id));
        // await displayValueCalculation();
        toast.success("Claiming successfully");
        
        await sleep(2000);
        setLoader(false)
        // await sleep(1600);
        window.location.reload();
    }catch(error){
        toast.error("Claiming is not succeed",`${error}`);
        console.log("error",error)
        setLoader(false)
    }
    }

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
     }

    const toastDiv = (txId,type) =>
    (
        <div>
           <p> {type} &nbsp;<a style={{color:'#AA14F0'}} href={txId} target="_blank" rel="noreferrer"><br/>View in Snow Trace Explorer <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.7176 3.97604L1.69366 14L0.046875 12.3532L10.0697 2.32926H1.23596V0H14.0469V12.8109H11.7176V3.97604Z" fill='#AA14F0'/>
    </svg></a></p> 
        </div>
    );
      
    return (
        <div className='stock_filter_row'>
            <div className='stock_list_text_row'>
                <div className='Staked_box'>
                    <div className='Staked_list_p'>
                        <p className='Market_text_p'>{Item.StakedName}</p>
                        <p className='Market_text_p'>{Item.StakableName}</p>
                    </div>
                    <div className='Staked_list_p'>
                        <h6>{Item.StakedNameNum}</h6>
                        <h5>{Item.StakableNameNum? parseFloat(Item.StakableNameNum).toFixed(3) :'0'} LP</h5>
                    </div>
                </div>
            </div>
            <div className='Stake_All_btns'>
                <p>{Item.Stake_All_btns_text}</p>
                {Item.All_btns_text === "Claim" ? (<>
                {(Item.StakableNameNum*1e18) >= Item.rewardtoclaim ? (<>
                    {/* <div className='All_btns'>
                     <a href="#0" onClick={()=>claim()}>{Item.All_btns_text}</a>
                     
                </div> */}
                <div className='approve_button hero_btn'>
                      <ButtonLoad loading={loader}onClick={()=>claim()} >{Item.All_btns_text}</ButtonLoad>
                      </div>
                </>):(<>
                    <WebOffBotton WebOffBotton="Claim" link="#0"  />
                </>)}
                </>):(<>
                {Item.All_btns_text === "Unstake" ? (<>
                {
                   
                  ( lock === true)? (<>
                    {/* <div className='All_btns'>
                  {(Item.unstakeTime)}
                    </div> */}
<CountdownWrapper endDate = {Item.unstakeTime}	/>

                    </>):(<> 
                   <div className='All_btns'>
                   <a href="#0" onClick={()=>handleOpens()}>{Item.All_btns_text}</a>
                   
                   
              </div></>) 
                }
                </>):(<>
                    <div className='All_btns'>
                     <a href="#0" onClick={()=>handleOpen()}>{Item.All_btns_text}</a>
                     
                </div>
                </>)}
                   
                </>)}
                {/* <CountdownWrapper endDate = {Item.unstakeTime}	/>
                <a href="#0" onClick={()=>handleOpens()}>{Item.unstakeTime}</a> */}
                {/* <div className='All_btns'>
                     <a href="#0" onClick={()=>handleOpens()}>{Item.All_btns_text}</a>
                     
                </div> */}
                 
            </div>
        </div>
    )
}

export default StockFilter;